/* You can add global styles to this file, and also import other style files */
// $grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl:1400px);
// $container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl:1320px) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px,
  xxl: 1920px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1350px,
  xxl: 1680px,
) !default;

@import "styles-config";
@import "@spartacus/styles/index";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "../src/styles/saco/colors";
@import "../src/styles/saco/fonts";
@import "../src/styles/saco/global";

:root {
  --cx-color-medium: $black-color;
}
* {
  box-sizing: border-box;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.form-control:focus,
input:focus {
  outline-color: transparent;
  outline-offset: 0;
}
input[type="checkbox"],
.form-check input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border-width: 1px;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
}

.form-check input[type="checkbox"]:checked {
  background-color: transparent;
  border-color: $black-color !important;
}
.form-check input[type="checkbox"]:checked::after {
  border-color: $black-color !important;
}
input[type="radio"]:checked {
  border-color: $black-color !important;
  background-color: $white-color;
}
input[type="radio"]:checked::after {
  background-color: $black-color !important;
}
body {
  @include font-Roboto-Medium();
  font-size: var(--fontsize14);
  overflow-x: hidden;
  color: $black-color;
}
.custom-primary-btn {
  background-color: $primary-color;
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
  @include font-Roboto-Regular();
  display: flex;
  height: 36px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: $white-color;
  text-align: center;
  font-size: var(--fontsize14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 36px;
  border-radius: 6px;
  &:hover{
    color: $white-color;
  }
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-color;
  border-color: $primary-color;
  opacity: 1;
  filter: none;
}
.custom-primary-outLine {
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
  @include font-Roboto-Medium();
  display: flex;
  height: 36px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  font-size: var(--fontsize14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 36px;
  border: 1px solid $primary-color;
  color: $primary-color;
  text-transform: inherit;
}

.login-page-logo {
  background-color: $white-color;
  padding: 2.7rem 2rem;
  border-bottom: 1px solid $border-color;
}
.account-section {
  margin-top: 4rem;
  width: 100%;
  max-width: 438px;
}
.create-account {
  padding: 1.5rem;
  background-color: $white-color;
  margin-bottom: 2rem;
}

.sigin-small-txt {
  color: $blue-menu-bar-bg;
  cursor: pointer;
  &:hover {
    color: $blue-menu-bar-bg;
    text-decoration: underline;
  }
}
.custom-input-box {
  border: none;
  border-bottom: 1px solid $border-variant1;
  width: 100%;
  height: 46px;
  color: $grey-variant1;
  @include font-Roboto-Regular();
  font-size: var(--fontsize14);
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}
.password-info {
  color: $black-color;
  @include font-Roboto-Medium();
  font-size: var(--fontsize12);
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  vertical-align: middle;
}
.help-info {
  color: $blue-header-bg;
  @include font-Roboto-Medium();
  font-size: var(--fontsize14);
  font-style: normal;
  line-height: 18px;
  &:hover {
    text-decoration: underline;
  }
}

// Loader
.loading {
  position: fixed;
  z-index: 1051;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none; //need to recheck
}

/* Transparent Overlay */

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.error-icon {
  background-image: url("./assets/image/error-icon.svg");
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: 0 4px;
}
[dir="rtl"] {
  .error-icon{
    padding-left: 0;
    padding-right: 20px;
    background-position: right;
  }
  .discount-price, 
  .total-price, .price-text, 
  .subtotal-label, 
  .cart-page-container .product-price,
  .price-discount-label,
  .price-box .actual-price,
  .price-label-text,
  .actual-price
   {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .checkout-main-container .checkout-left-conntainer .delivery-options .accordion-custom {
    padding-right: 60px;
  }
  .form-box-container {
    margin-left: 0 !important;
  }
  .accordion {
    .cardview {
      .card-header {
         padding-right: 2rem;
      }
    }
  }
}
cx-page-layout.sacoHomepagePageTemplate {
  background: $grey-variant5;
  cx-page-slot.has-components {
    @extend .container;
    margin-bottom: 30px;
    &:first-child {
      padding: 0;
    }
    &:last-child {
      margin-bottom: 0;
      //padding-bottom: 30px;
      padding: 0;
    }
  }
}
//OTB Product carousal
cx-product-carousel {
  background: $white-color;
  padding: 16px 20px;
  h2 {
    text-align: left;
    margin: 0;
    font-size: var(--fontsize24);
  }
  .cx-product-name,
  .price {
    text-align: left;
    padding-right: 15px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }
  .previous,
  .next {
    background-color: $grey-variant7;
    height: 100px;
    line-height: 85px;
    min-width: 60px;
    text-align: center;
    box-shadow: 0 1px 3px $grey-variant4;
    color: $black-color;
    position: relative;
    top: 20%;
    &:hover {
      opacity: 0.75;
      color: $black-color;
    }
  }
  cx-media.is-missing {
    min-height: 200px;
  }
}
//Custom Category Carousal
cx-carousel {
  &.custom-carousel {
    background: $white-color;    
    // padding: 16px 20px;
    @media screen and (min-width: 1024px) {       
      //padding: 65px; // need to check with me : siva
    }
    h2 {
      text-align: left;
      margin: 0 0 20px 0;
      font-size: var(--fontsize24);
    }
    .previous,
    .next {
      background-color: $black-color;
      height: 48px;
      min-width: 48px;
      text-align: center; 
      color: $white-color;
      position: relative;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s;
      border: none;
      font-size: 1.5rem;
      &:disabled{
          background-color: grey;
      }
        &:hover {
        opacity: 0.75;
        color: $white-color;
      }
    }
    cx-media {
      text-align: center;
      &.is-missing {
        min-height: 200px;
      }
    }
  }
  .slide {
    .item {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .slides {
    .item {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
@media (max-width:$screenMedium-1024) {

  cx-carousel.custom-carousel .previous,
  cx-carousel.custom-carousel .next {
    height: 38px;
    min-width: 38px;
    font-size: 1.2rem;
  }

  cx-banner-carousel {
    .carousel-panel {
      .next,
      .previous {
        width: 38px !important;
        height: 38px !important;
        min-width: 38px !important;
        margin: 0 .5rem !important;
        .cx-icon {
          font-size: 1.2rem !important;
        
        }
      }
    }
  }
}
@media (min-width:$screenMedium-1024) {
  cx-banner-carousel {
    .carousel-panel {
      .next,
      .previous {
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        margin: 0 1rem !important;
        .cx-icon {
          font-size: 1.5rem !important;
        
        }
      }
    }
  }
}

cx-carousel {
  .carousel-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.custom-carousel-heading {
  display: flex;
  background: $white-color;
  padding: 16px 20px 16px 16px;
  h2 {
    font-size: var(--fontsize20);
  }
  a {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    // padding-top: 2px;
    font-size: var(--fontsize14);
    @include font-Roboto-Medium();
  }
}
// Hide dotted indicators
cx-carousel,
cx-product-carousel {
  .indicators {
    display: none;
  }
}
cx-banner-carousel {
  .carousel-panel {
    .next,
    .previous {
      color: $white-color;
      background-color: black;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      margin: 0 1rem !important;
      &:hover {
        color: $white-color;
      }
      .cx-icon{
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;
          font-size: 1.5rem;
      }
    }
  }
}
h1,
h2 {
  @include font-Roboto-Bold();
  color: $black-variant2;
}
h1,
.pageTitle {
  font-size: var(--fontsize32);
  padding-top: 1.5rem;
}
header {
  header {
    cx-page-layout {
      &:first-child {
        @extend .container;
      }
    }
  }
  background: none;
  background-color: $blue-variant2;
  .header {
    max-width: 100%;
    padding: 0;
  }
  .NavigationBar {
    min-height: 63px;
  }
}
//need to update for first cx-page-slot
cx-page-layout.sacoHomepagePageTemplate {
  cx-page-slot {
    &:nth-child(2) {
      margin-top: -10px;
      // @media screen  and (max-width: $Largescreen-1600) and (min-width: $Largescreen-1440) {        
      //   margin-top: -200px;
      // }
      
      @media screen  and (max-width: $screenMedium-1200) and (min-width: $screenMedium-1024) {        
        margin-top: -160px;
      }
      z-index: 11;
      position: relative;
    }
  }
}
header .SiteLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 11.5%;
  a {
    width: 90px;
  }
  cx-banner {
    margin-right: 40px;
  }
}
header {
  header .MiniCart {
    padding-right: 2%;
  }
  cx-searchbox {
    display: none;
  }
  .MiniCart {
    cx-link{
      display: none;
    }
  }
  // .SearchBox {
  //   display: flex;
  //   flex-wrap: nowrap;
  //   margin: 14px 2% 14px 15%;
  //   align-items: center;
  // }
}
// app-language-selection {
//   display: flex;
//   justify-content: right;
//   align-items: center;
//   height: 40px;
//   width: 14rem;
//   margin-left: 0%;
// }
header .SiteLogin {
  display: none;
}
.MiniCart cx-link a {
  color: $black-color;
  text-decoration: none;
  padding-right: 3rem;
  max-width: 138px;
  display: inline-block;
  line-height: 18px;
}
// header app-custom-account {
//   display: flex;
//   justify-content: center;
// }
.info-img{
  width: 18px !important;
  margin-right: 10px; 
}
[dir="rtl"]header {
  .SearchBox {
    margin: 14px 0 14px 1%;
    app-language-selection {
      margin-right: 5%;
    }
  }
  .MiniCart cx-link a {
    padding-left: 3rem;
    padding-right: 0;
  }

  .SiteLogo {
    margin-right: 0;
    padding-right: 10.5%;
    a {
      width: 130px;
    }
    cx-banner {
      margin-left: 40px;
    }
  }
}
@media screen and (max-width: $Largescreen-1600) and (min-width: $Largescreen-1440) {
  header .SearchBox {
    margin: 14px 2% 14px 15%;
  }
  app-language-selection {
    width: 13rem;
    margin-left: 0%;
  }
  header .SiteLogo {
    padding-left: 14.5%;
  }
  header .SiteLogo cx-banner {
    margin-right: 12px;
  }
}
@media screen and (max-width: $Largescreen-1760) and (min-width: $Largescreen-1700) {
  header .SearchBox {
    margin: 14px 2% 14px 15%;
  }
}
@media screen and (max-width: $Largescreen-1798) and (min-width: $Largescreen-1760) {
  header .SearchBox {
    margin: 14px 2% 14px 15%;
  }
}
@media screen and (max-width: $Largescreen-1700) and (min-width: $Largescreen-1600) {
  header .SiteLogo {
    padding-left: 12.5%;
  }
  app-language-selection {
    width: 19rem;
    margin-left: 6%;
  }
  header .SearchBox {
    margin: 14px 2% 14px 15%;
  }
}
@media screen and (max-width: $Largescreen-1440) and (min-width: $ScreenMedium-1366) {
  header .SiteLogo {
    padding-left: 16.5%;
  }
  header .SearchBox {
    margin: 14px 1% 14px 15%;
  }
}
@media screen and (max-width: $ScreenMedium-1365) and (min-width: $ScreenMedium-1300) {
  header .SiteLogo {
    padding-left: 16.5%;
  }
  header .SearchBox {
    margin: 14px 1% 14px 15%;
  }
  [dir="rtl"] header .SiteLogo {
    margin-right: 0;
    padding-right: 15.5%;
  }
}
@media screen and (max-width: $ScreenMedium-1300) and (min-width: $screenMedium-1200) {
  header .SiteLogo {
    padding-left: 19.5%;
    cx-banner {
      margin-right: 10px;
    }
  }
  header .SearchBox {
    margin: 14px 1% 14px 15%;
  }
  cx-banner {
    margin-right: 10px;
  }
  custom-address-container {
    font-size: 0.75rem;
  }
  app-language-selection {
    width: 9rem;
  }
  .MiniCart cx-link a {
    font-size: 0.75rem;
    padding: 0;
  }
  header app-custom-account {
    font-size: 0.75rem;
  }
  .language-selector span {
    font-size: 0.7rem;
  }
  app-custom-location .delivery-to {
    font-size: 10px;
  }
  .lang-selector-container .language-selector span {
    font-size: 10px !important;
  }
  [dir="rtl"]header .SiteLogo {
    padding-right: 17.5%;
    margin-right: 0;
  }
}
.product-price{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: $screenMedium-1200) and (min-width: $screenMedium-1024) {
  header .SiteLogo {
    padding-left: 16%;
    cx-banner {
      margin-right: 5px;
    }
  }
  [dir="rtl"]header .SiteLogo {
    padding-right: 20.5%;
    padding-left: 7%;
    cx-banner {
      margin-right: 5px;
    }
  }

  app-custom-location .custom-address-container {
    width: 160px;
    font-size: 12px;
  }
  header .SearchBox {
    margin: 14px 1% 14px 14%;
  }
  app-language-selection {
    width: 9rem;
    margin-left: 0%;
    font-size: 12px;
    .language-selector {
      padding: 10px 0;
      span {
        font-size: 12px !important;
      }
    }
  }
  .accounts-lists-container {
    font-size: 12px;
  }
  header header .MiniCart {
    padding-right: 0;
  }
  header cx-link {
    font-size: 12px;
    a {
      padding-right: 1rem;
    }
  }
}
.font-weight-normal {
  font-weight: normal !important;
}
.toast {
  top: 20%;
  left: 50%;
  position: fixed;
  min-width: 350px;
  max-width: 500px;
  transform: translate(-50%, 0px);
  z-index: 9999;
  background-color: $black-variant2;
  box-shadow: none;
  border: none;
  .toast-body {
    color: $white-color;
    text-align: left;
    img {
      margin-right: 10px;
      width: 25px;
    }
    .toast-close {
      top: 50%;
      right: 5%;
      position: absolute;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .toast-header {
    background-color: $black-variant2;
    color: $white-color;
    padding: 15px;
    border-radius: 0;
    .close {
      color: $white-color;
      opacity: 1;
      text-shadow: none;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.btn {
  font-weight: normal !important;
  line-height: 20px;
  padding: 10px 30px;
  height: auto;
  min-height: 42px;
  border-radius: 4px;
  &:focus, &.focus{
    box-shadow: none !important;
  }
}

//code for custom scrollbar
// ::-webkit-scrollbar {
//   width: 15px;
//   height: 2px;
// }

::-webkit-scrollbar-track {
  background: $gray-variant9;
}

::-webkit-scrollbar-thumb {
  background: $grey-variant2;
}
//code for place holder color
::placeholder {
  color: $grey-variant1 !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: $grey-variant1 !important;
}
textarea::placeholder {
  color: $grey-variant1 !important;
}
.modal {
  .modal-header {
    background: $gray-variant13;
    border-radius: 10px;
    // .close {
    //     bottom: 20px;
    // }
  }
  .modal-content {
    border-radius: 5px;
  }
}
.loyalty-points {
  //background-color: $color-success;
  background-color: $gold-color;
  padding: 7px;
  color: $white-color;
  font-size: var(--fontsize12) !important;
  margin: 16px 0;
  img {
    margin-right: 5px;
    width: 14px;
    height: 15px;
    margin-top: 1px;
  }
}
.loyalty-points-label {
  padding: 7px;
  line-height: 18px;
  color: $white-color;
  background-color: $gold-color;
  margin-top: 10px;
  margin-bottom: 10px;
}
html[dir="rtl"] {
  overflow-x: hidden;
  cx-banner-carousel {
    .inline-navigation {
      .carousel-panel {
        .next {
          left: 0;
          right: auto;
        }
      }
    }
  }
}
.text-decoration-underline {
  text-decoration: underline;
}
.txt-instock {
  color: $color-success;
  //color: $black-color;
}
.txt-outofstock {
  color: $brand-variant1;
}
.txt-limitedstock, .txt-lowstock {
  color: $warning;
}
.custom-alert-message {
    border: 1px solid $brand-variant1;
    border-radius: 15px;
    justify-content: flex-start;
    .icon-label {
        img {
            width: 35px;
        }
    }
    .custom-alert-message-header {
        color: $brand-variant1;
        font-size: var(--fontsize16);
    }
    .custom-alert-message-list {
        li {
            font-weight: normal;
            font-size: var(--fontsize14);
        }
    }
}


.p-carousel-container {
  @extend .container;
  background: $white-color;
}

.bullet-points {
    padding-left: 25px;
    padding-right: 25px;
  li {                   
      list-style: disc !important;
      list-style-type: disc;
  }
}
.left-right-staticpage {
    font-size: var(--fontsize16);
    p,ul {
        @include font-Noto-Sans-Regular();
    }
    .left-tab-container {
        .nav-pills {
            .nav-link {
                background: none;
                padding: 15px; 
                border-radius: 0;
                font-size: var(--fontsize18);
                color: $black-variant2;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;                    
                &.active, &:hover {
                    color: $black-variant2;
                    background: $white-variant1;
                    .icon-field {
                        color: $primary-color;
                    }
                }    
                &.child-link {
                  padding-left:35px;
                }         
            }
        } 
    }
}

.staticpage-accordion {
    // h3 {
    //   border-bottom: 1px solid $border-color;
    //   padding-bottom: 1rem;
    // }
  .card-header {
      font-size: var(--fontsize18);
      background: none;
      color: $black-color;
      font-weight: normal;
      cursor: pointer;
      border-bottom: none;
      font-size: var(--fontsize16);
      display: flex;
      justify-content: space-between;
      .accicon {
        color: $primary-color;
        //float: right;
        font-size: var(--fontsize18);  
        width: 1.2em;
      }
      &:not(.collapsed) .rotate-icon {
          transform: rotate(180deg);
      }
  }
  .card-body {
    padding: 15px 20px;
    @include font-Roboto-Regular();
    border-top: 1px solid $gray-variant15;
  }
  .card {
    margin-bottom: 0;
    border: 1px solid $gray-variant15;
  }
}

iframe{    
  display: block; 
  border: none;
  width: 100%;
  &.full-height {
    height: 90vh;   
  }
  &.minimum-height {
    height: 30px;
  }
  &.middle-height {
    height: 420px;
  }
  &.set-max-height {
    height: 100vh;
  }
}


@media screen and (max-width: $screenMedium-1024) { 
  .d-lg-block {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .hide-small-device {
    display: none !important;
  }
  .show-small-device {
    display: block !important;
  }
}
@media screen and (max-width:$screenMedium-1024) and (min-width:$screenMedium-1024){

  .medium-device {
    margin-left: -6rem !important;
}
.location-medium-device {
    margin-left: 0rem !important;
    margin-top: -2rem;
    padding-top: 0rem !important;
}
    
}
@media screen and (max-width:$screenMedium-1023) and (min-width:$screenMedium-992){

  .location-medium-device{
    margin-left: -21rem !important;
    margin-top: -2rem;
    padding-top: 0rem !important;
  }
  .medium-device {
    margin-left: -11rem !important;
}
    
}
@media screen and (max-width:$screenMedium-991) and (min-width: $mobileScreenPotrait-768) {
  .medium-device {
    margin-left: -8rem !important;
  }
  .location-medium-device{
    margin-left: -6rem !important;
  }
}
@media screen and (max-width:$mobileScreenPotrait-768) and (min-width: $smallmobileScreenPotrait576) {
  .medium-device {
    margin-left: -8rem !important;
  }
  .medium-device-position{
    margin-top: 0rem !important;
  }
  .location-medium-device{
    margin-left: -6rem !important;
  }
}
@media screen and (max-width:$smallmobileScreenPotrait576)  {
  .medium-device {
    margin-left: -8rem !important;
  }
  .medium-device-position{
    margin-top: 0rem !important;
    margin-left: 14rem;
  }
  .location-medium-device{
    margin-left: 6rem !important;
  }
}
@media screen and (max-width:$mobileScreenPotrait-767) and (min-width: $smallmobileScreenPotrait577)  {
  .medium-device-position{
    margin-top: 2rem !important;
  }
}
@media screen and (max-width:$smallmobileScreenPotrait568)  {
  .medium-device {
    margin-left: -11rem !important;
  }
  .location-medium-device{
    margin-left: 5rem !important;
  }
}
@media screen and (max-width:$smallmobileScreenPotrait500)  {
  .location-medium-device{
    margin-left: 3rem !important;
  }
}
@media screen and (max-width:$smallmobileScreenPotrait440)  {
  .medium-device {
    margin-left: -13rem !important;
  }
  .location-medium-device{
    margin-left: 1rem !important;
  }
}
@media screen and (max-width:$smallmobileScreenPotrait375)  {
  .medium-device {
    margin-left: -14rem !important;
  }
  .location-medium-device{
    margin-left: 0rem !important;
  }
}
//arabic
[dir="rtl"] {
  @media screen and (min-width:$screenMedium-1024) and (max-width:$screenMedium-1024){
    .medium-device {
      margin-right: -2.2rem !important;
    }
    .location-medium-device{
      margin-right: -9.5rem;
    }
    .accounts-lists-container{
      margin-right: 0rem !important;
    }
  }
  @media screen and (max-width:$screenMedium-1023) and (min-width:$screenMedium-992){
    .medium-device {
      margin-right: -9.2rem !important;
    }
  
    .medium-device-position {
      margin-top: 0rem !important;
    }
  
    .location-medium-device {
      margin-right: -16rem !important;
    }
  }
  
  @media screen and (max-width:$screenMedium-991) and (min-width: $mobileScreenPotrait-768) {
    .medium-device {
      margin-right: -9.2rem !important;
    }
  
    .medium-device-position {
      margin-top: 0rem !important;
      margin-right: 1rem;
    }
  
    .location-medium-device {
      margin-right: -8rem !important;
    }
  }
  @media screen and (max-width:$mobileScreenPotrait-768){
    .medium-device {
      margin-right: -8rem !important;
    }
    .medium-device-position{
      margin-top: 0rem !important;
    }
    .medium-device-position{
      margin-top: 2rem !important;
    }
  }
  @media screen and (max-width:$mobileScreenPotrait-767) {
    .location-medium-device{
      margin-right: -4rem !important;
    }
    .medium-device-position{
      margin-top: 2rem !important;
    }
  }
  @media screen and (max-width:$smallmobileScreenPotrait576)  {
    .medium-device {
      margin-right: -8rem !important;
    }
    .medium-device-position{
      margin-top: 0rem !important;
      margin-right: 3rem;
    }
    .location-medium-device{
      margin-right: 3rem !important;
    }
  }

  @media screen and (max-width:$smallmobileScreenPotrait568)  {
    .medium-device {
      margin-right: -11rem !important;
    }
    .location-medium-device{
      margin-right: 1rem !important;
    }
  }
  @media screen and (max-width:$smallmobileScreenPotrait500)  {
    .location-medium-device{
      margin-right: -1rem !important;
    }
  }
  @media screen and (max-width:$smallmobileScreenPotrait440)  {
    .medium-device {
      margin-right: -13rem !important;
    }
    .location-medium-device{
      margin-right: -3rem !important;
    }
  }
  @media screen and (max-width:$smallmobileScreenPotrait375)  {
    .medium-device {
      margin-right: -14rem !important;
    }
    .location-medium-device{
      margin-right: -3rem !important;
    }
  }
}

@media screen and (max-width: $mobileScreenPotrait-767) {
  h1, .pageTitle {
    font-size: var(--fontsize26);
    padding-top: 1.5rem;
  }
  .pagination-container {
    .page-link {
        min-width: 35px !important;
        min-height: 35px !important;
    }
}
}
.redeem-value {
  color: $brand-variant1;
}
.delivery-date {
  color: $black-color;
  font-size: var(--fontsize14);
}
.flex-initial {
  display: initial !important;
}
.see-more-link {
  font-size: var(--fontsize14);
  text-decoration: none;
  @include font-Roboto-Medium();
  padding-right: 15px;
  color: $blue-header-bg;
  &:hover {
    color: $blue-menu-bar-bg;
    text-decoration: underline !important;
  }
  a {
    display: inline;
  }
}

cx-page-layout.sacoHomepagePageTemplate cx-page-slot.has-components:last-child {
  max-width: 100% !important;
}

@media (max-width: $Largescreen-1440) {
  .container {
    &.home-page-container {
      max-width: 100% !important;
    }
  }

  .home-page-container{
   .section-one-main {
    .col-12 {
      padding: 0 0;
    }
    cx-carousel {
      .slide {
        .item {
          padding: 0;
        }
      }
    }
   }
  }
}
sup {
  padding-left: 3px !important;
  font-size: 12px;
}
.actual-price {
  span, sup, sub {
    text-decoration: line-through;
  }
}

.text-truncate-label{
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
  line-height: 1.5em;
  white-space: normal !important;
}
// custom color for primery button

// .custom-primary-btn, .btn-primary, .back-to-top {
//   background-color: $saco-brand !important;
//   color: $white-color !important;
//   border-color: $saco-brand;
//   &:hover, &:focus, &:visited, &:active {
//     background-color: $saco-brand !important;
//     color: $white-color !important;
//     border-color: $saco-brand !important;
//   }
// }
// .custom-primary-outLine, .btn-outline-primary{
//   border-color: $saco-brand;
//   color: $saco-brand;
//   &:hover, &:focus, &:active {
//     background-color: $saco-brand !important;
//     color: $white-color;
//     border-color: $saco-brand !important;
//   }
// }
// .btn.cancel-btn, .remove-btn{
//     color: $saco-brand;
// }
// orange primary button
.cta-btn-primary{
  background-color: $cta-primary !important;
  color: $black-color !important;
  border-color: $cta-primary;
  font-size: var(--fontsize16);
  @include font-Roboto-Bold();
  text-transform: inherit;
  border-radius: 4px;
  &:hover, &:focus, &:visited, &:active {
    background-color: $cta-primary-hover !important;
    color: $black-color !important;
    border-color: $cta-primary-hover;
  }
}
//blue button
.cta-btn-secondary, .btn-primary  {
  background-color: $blue-header-bg !important;
  color: $white-color !important;
  border-color: $blue-header-bg;
  font-size: var(--fontsize16);
  @include font-Roboto-Bold();
  text-transform: inherit;
  border-radius: 4px;
  &:hover, &:focus, &:visited, &:active {
    background-color: $blue-menu-bar-bg !important;
    color: $white-color !important;
    border-color: $blue-menu-bar-bg;
  }
}
//blue outline button
.cta-btn-primary-outline, .btn-outline-primary, .custom-primary-outLine, .btn-secondary, .edit-btn  {
  background-color: $white-color  !important;
  color: $blue-header-bg !important;
  border-color: $blue-header-bg;
  font-size: var(--fontsize16);
  @include font-Roboto-Bold();
  text-transform: inherit;
  border-radius: 4px;
  &:hover, &:focus, &:visited, &:active {
    background-color: $blue-menu-bar-bg !important;
    color: $white-color !important;
    border-color: $blue-menu-bar-bg;
  }
}
.link-text, .btn-link {
  color: $blue-header-bg !important;
  &:hover {
    color: $blue-menu-bar-bg !important;
    text-decoration: underline;
  }
}
a {
  color: $blue-header-bg;
  &:hover {
    color: $blue-menu-bar-bg !important;
  }
  &:active {
    outline: none;
    outline-style:none;
  }
}

.location-popup-header {
  background-color: $blue-header-bg !important;
  color: $white-color;
}
#DeliveryExperienceModal .review-logo, #DeliveryExperienceModal .modal-header {
  @extend .location-popup-header;
    margin-top: 0 !important;
    padding-top: 10px;
    border-radius: 0;
    .close {
      color: $white-color;
      opacity: 1;
    }
}
[dir="rtl"] { 
  .info-img{
    width: 18px !important;
    margin-right: 5px;
    margin-left: 10px;
  }
  .toast {
    .toast-body {
      text-align: right;
    }
  }
}
.cursor-pointer-none {
  pointer-events: none;
}
.delivery-info {
  color: $black-variant1;
  cx-media {
    width: 45px;
    margin: auto;
  }
}