.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

cx-store-finder {
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-store-finder .cx-store-finder-wrapper {
  text-align: center;
  padding: 3rem 0;
}
cx-store-finder .cx-pagination .pagination a.page-link:hover {
  color: #fff;
  font-weight: normal;
}

cx-store-finder-grid > .container {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: var(--cx-color-light);
  display: inline-block;
  padding: 10px 0 0 0;
}
@media (max-width: 767.98px) {
  cx-store-finder-grid > .container {
    padding: 0 15px;
  }
}
cx-store-finder-grid .cx-spinner {
  padding: 2rem 0;
}

cx-store-finder-list-item {
  display: block;
  text-align: start;
  padding: 1rem 0.75rem;
  text-decoration: none;
  position: relative;
}
@media (max-width: 991.98px) {
  cx-store-finder-list-item {
    padding: 0.5rem;
  }
}
cx-store-finder-list-item:hover {
  background-color: var(--cx-color-background);
}
cx-store-finder-list-item .cx-store-list-order {
  position: absolute;
  left: -2rem;
}
cx-store-finder-list-item .cx-store-name {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  min-height: 40px;
  cursor: pointer;
}
cx-store-finder-list-item .cx-store-address {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
}
cx-store-finder-list-item .cx-store-address-street {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 991.98px) {
  cx-store-finder-list-item .cx-store-address-street {
    white-space: normal;
  }
}
cx-store-finder-list-item .cx-store-open {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  color: var(--cx-color-success);
}
cx-store-finder-list-item .cx-store-closed {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  color: var(--cx-color-danger);
}
cx-store-finder-list-item .cx-store-distance {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-bold);
  margin: 1rem 0;
}
cx-store-finder-list-item .cx-button {
  line-height: 2;
  margin: 1rem 0 0;
}

cx-store-finder-store-description .container {
  margin-bottom: 1rem;
}
cx-store-finder-store-description .cx-store {
  text-align: start;
}
.cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .container {
  margin-bottom: unset;
}
.cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .cx-container {
  text-align: start;
  display: flex;
  margin-bottom: 1rem;
}
@media (max-width: 991.98px) {
  .cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .cx-container {
    flex-direction: column;
  }
}
.cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .cx-store {
  text-align: unset;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  flex: 1;
}

cx-store-finder-store-description .cx-store-description-address {
  margin-bottom: 1.5rem;
}
cx-store-finder-store-description .cx-contact {
  margin-bottom: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
}
cx-store-finder-store-description .cx-list {
  padding: 0;
  list-style: none;
}
cx-store-finder-store-description .cx-link {
  color: var(--cx-color-text);
}
cx-store-finder-store-description .cx-schedule {
  margin-bottom: 1.5rem;
}
cx-store-finder-store-description .cx-store-hours {
  padding: 0;
}
cx-store-finder-store-description .cx-hours {
  text-align: end;
}
cx-store-finder-store-description .cx-hours.closed {
  color: var(--cx-color-primary);
}
cx-store-finder-store-description .cx-features {
  margin-bottom: 10px;
}
cx-store-finder-store-description .cx-features-header {
  text-align: start;
}
cx-store-finder-store-description .cx-storeMap {
  height: 70vh;
}
.cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .cx-storeMap {
  height: unset;
  flex: 2;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}
.cxFeat_a11yStoreFinderOverflow cx-store-finder-store-description .cx-feature-item {
  color: var(--cx-color-primary);
}

cx-store-finder-store-description .cx-feature-value {
  color: var(--cx-color-primary);
}
cx-store-finder-store-description .cx-feature-item {
  text-align: start;
}

cx-store-finder-stores-count .cx-count {
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: var(--cx-color-light);
  display: inline-block;
}
cx-store-finder-stores-count .cx-set {
  margin: 0.5rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
cx-store-finder-stores-count .cx-set:hover {
  background-color: var(--cx-color-background);
}
cx-store-finder-stores-count a {
  padding: 1.5rem;
  width: 100%;
}
cx-store-finder-stores-count .cx-no-stores {
  display: block;
  padding-top: 4rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 1.5rem;
  padding-inline-start: 1.5rem;
  margin: 0 auto;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  color: var(--cx-color-secondary);
}
cx-store-finder-stores-count .cx-title {
  width: 100%;
}
cx-store-finder-stores-count .cx-name {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  padding-inline-end: 0.5rem;
}
cx-store-finder-stores-count .cx-country-count {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  font-weight: normal;
}
cx-store-finder-stores-count .cx-region-set {
  width: 100%;
}
cx-store-finder-stores-count .cx-region-directory {
  column-count: 1;
  list-style: none;
  padding-inline-start: 0;
}
@media (min-width: 768px) {
  cx-store-finder-stores-count .cx-region-directory {
    column-count: 4;
  }
}
cx-store-finder-stores-count .cx-directory-item {
  padding: 0;
  line-height: normal;
}
cx-store-finder-stores-count .cx-item-link {
  padding-top: 0;
  padding-inline-end: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline-start: 0.2rem;
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: normal;
}
cx-store-finder-stores-count .cx-item-count {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
}
cx-store-finder-stores-count .cx-count-spinner {
  padding: 30px 0;
}

cx-store-finder-search-result .cx-pagination {
  margin: 1rem auto;
  justify-content: center;
  display: flex;
}
cx-store-finder-search-result .cx-spinner {
  padding: 2rem 0;
}
cx-store-finder-search-result .cx-no-stores {
  display: block;
  padding-top: 4rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 1.5rem;
  padding-inline-start: 1.5rem;
  margin: 0 auto;
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  color: var(--cx-color-secondary);
}

cx-store-finder-list .cx-columns {
  display: none;
}
@media (min-width: 992px) {
  cx-store-finder-list .cx-columns {
    display: flex;
    height: 70vh;
    margin-bottom: 1rem;
  }
}
cx-store-finder-list .cx-columns-mobile {
  margin-top: 1rem;
  display: block;
}
@media (min-width: 992px) {
  cx-store-finder-list .cx-columns-mobile {
    display: none;
  }
}
cx-store-finder-list .cx-columns-mobile .cx-list li:first-child {
  border: none;
}
cx-store-finder-list .cx-columns-mobile .cx-map-col {
  height: 70vh;
}
cx-store-finder-list .cx-columns-mobile .cx-nav-item button {
  background: none;
  color: var(--cx-color-primary);
}
cx-store-finder-list .cx-address-col {
  height: 100%;
}
@media (min-width: 768px) {
  cx-store-finder-list .cx-address-col {
    height: 100%;
    overflow-y: auto;
    padding: inherit inherit inherit 0;
  }
}
cx-store-finder-list .cx-map-col {
  height: 100%;
}
@media (min-width: 768px) {
  cx-store-finder-list .cx-map-col {
    height: 100%;
    overflow-y: auto;
  }
}
cx-store-finder-list .cx-list {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  list-style: none;
  padding: inherit inherit inherit 0;
}
cx-store-finder-list cx-store-finder-list-item {
  margin: 0;
  padding: 1.25rem;
}
cx-store-finder-list .cx-list-items {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: var(--cx-color-light);
}
cx-store-finder-list .cx-list-items .cx-store-name {
  color: var(--cx-color-primary);
  cursor: default;
}
cx-store-finder-list .cx-list-items .cx-store-name a {
  cursor: pointer;
}
cx-store-finder-list .cx-list-items .cx-store-name a:hover {
  text-decoration: underline;
}
cx-store-finder-list .cx-list-items:hover {
  background-color: var(--cx-color-background);
}
cx-store-finder-list .cx-list-items.cx-selected-item {
  background-color: var(--cx-color-background);
}
cx-store-finder-list .cx-list-items.cx-selected-item:hover {
  background-color: var(--cx-color-background);
}
cx-store-finder-list .cx-not-found {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  text-align: center;
  padding: 3rem 0;
}
@media (min-width: 768px) {
  cx-store-finder-list .cx-store,
  cx-store-finder-list .cx-feature-item {
    max-width: 100%;
    flex: 100%;
  }
}
cx-store-finder-list .cx-feature {
  margin-bottom: 0.5rem;
}
cx-store-finder-list .cx-back-wrapper {
  margin: 0.5rem 1rem;
  min-width: 180px;
}
cx-store-finder-list .cx-back {
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
}
cx-store-finder-list .cx-back cx-icon {
  margin: 0 0.2rem 0 0;
}
cx-store-finder-list .cx-nav {
  border-bottom: 1px solid var(--cx-color-light);
  justify-content: center;
}

cx-store-finder-search {
  display: block;
  margin: 0 0 1rem;
}
@media (max-width: 767.98px) {
  cx-store-finder-search .cx-search-links button {
    padding: 6px;
    height: initial;
    max-height: initial;
    min-height: 48px;
  }
}
cx-store-finder-search .search-wrapper {
  position: relative;
}
cx-store-finder-search .search-wrapper input {
  padding-inline-end: 46px;
}
cx-store-finder-search .search-wrapper cx-icon {
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
}
cx-store-finder-search .disabled-action {
  pointer-events: none;
  opacity: 0.5;
}

cx-store-finder-schedule .cx-days {
  padding: 0 1rem 0 0;
}
cx-store-finder-schedule .cx-store-hours {
  margin: 1.5rem 0;
}
cx-store-finder-schedule .cx-hours {
  text-align: center;
}
cx-store-finder-schedule .cx-hours.closed {
  color: var(--cx-color-primary);
}

cx-store-finder-map .cx-store-map {
  width: 100%;
  height: 100%;
}
@media (max-width: 767.98px) {
  cx-store-finder-map .cx-store-map {
    height: 30rem;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .cxFeat_a11yStoreFinderOverflow cx-store-finder-map .cx-store-map {
    height: unset;
    width: unset;
  }
}
@media (max-width: 991.98px) {
  .cxFeat_a11yStoreFinderOverflow cx-store-finder-map .cx-store-map {
    height: 30rem;
    width: 100%;
  }
}

cx-store-finder-store .cx-store-actions {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  cx-store-finder-store .cx-store-actions {
    width: 25%;
  }
}
@media (max-width: 991.98px) {
  cx-store-finder-store .cx-store-actions {
    width: 30%;
  }
}
@media (max-width: 767.98px) {
  cx-store-finder-store .cx-store-actions {
    width: 100%;
  }
}
cx-store-finder-store .cx-store-actions button {
  outline: none;
  min-width: 150px;
}

cx-page-layout.StoreFinderPageTemplate cx-page-slot.SideContent cx-banner img {
  width: auto;
}