@import '../saco/global';

@font-face {
  font-family: 'Roboto Regular';
  src: url('../../assets/fonts/roboto-regular-webfont.eot');
  src: url('../../assets/fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('../../assets/fonts/roboto-regular-webfont.woff') format('woff'),
       url('../../assets/fonts/roboto-regular-webfont.ttf') format('truetype'),
       url('../../assets/fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Roboto Medium';
  src: url('../../assets/fonts/roboto-medium-webfont.eot');
  src: url('../../assets/fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/roboto-medium-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-medium-webfont.woff') format('woff'),
    url('../../assets/fonts/roboto-medium-webfont.ttf') format('truetype'),
    url('../../assets/fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('../../assets/fonts/roboto-bold-webfont.eot');
  src: url('../../assets/fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-bold-webfont.woff') format('woff'),
    url('../../assets/fonts/roboto-bold-webfont.ttf') format('truetype'),
    url('../../assets/fonts/roboto-bold-webfont.svg#robotobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans Regular";
  src: url('../../assets/fonts/noto-sans-regular.eot') format('embedded-opentype'),
    url('../../assets/fonts/noto-sans-regular.woff') format('woff'),
    url('../../assets/fonts/noto-sans-regular.woff2') format('woff2'),
    url('../../assets/fonts/noto-sans-regular.ttf') format('truetype'),
    url('../../assets/fonts/noto-sans-regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// below mixin need to be removed  
@mixin font-Roboto() {
  font-family: "Roboto Medium", sans-serif;
}

@mixin font-Roboto-Regular() {
  font-family: "Roboto Regular", sans-serif;
}

@mixin font-Roboto-Medium() {
  font-family: "Roboto Medium", sans-serif;
}

@mixin font-Roboto-Bold() {
  font-family: "Roboto Bold", sans-serif;
}

@mixin font-Noto-Sans-Regular() {
  font-family: "Noto Sans Regular", sans-serif;
}

@media screen and (min-width: $ScreenMedium-1366) {
  :root {
    --fontsize5: 0.5rem;
    --fontsize10: 0.625rem;
    --fontsize12: 0.75rem;
    --fontsize14: 0.875rem;
    --fontsize16: 1rem;
    --fontsize17: 1.063rem;
    --fontsize17-5: 1.1rem;
    --fontsize18: 1.125rem;
    --fontsize18-4: 1.5rem;
    --fontsize20: 1.25rem;
    --fontsize21: 1.313rem;
    --fontsize22: 1.375rem;
    --fontsize23: 1.438rem;
    --fontsize24: 1.5rem;
    --fontsize26: 1.625rem;
    --fontsize28: 1.75rem;
    --fontsize30: 1.625rem;
    --fontsize32: 2rem;
    --fontsize34: 2.125rem;
    --fontsize36: 2rem;
    --fontsize40: 2.25rem;
    --fontsize46: 2.875rem;
    --fontsize54: 3.375rem;
    --fontsize80: 5rem;
  }
}

@media screen and (max-width: $ScreenMedium-1365) and (min-width: $mobileScreenPotrait-768) {
  :root {
    --fontsize5: 0.5rem;
    --fontsize10: 0.625rem;
    --fontsize12: 0.75rem;
    --fontsize14: 0.875rem;
    --fontsize16: 1rem;
    --fontsize17: 0.90rem;
    --fontsize17-5: 1rem;
    --fontsize18: 0.875rem;
    --fontsize20: 1rem;
    --fontsize21: 1.063rem;
    --fontsize22: 1.125rem;
    --fontsize23: 1.188rem;
    --fontsize24: 1rem;
    --fontsize28: 1.5rem;
    --fontsize30: 1.25rem;
    --fontsize32: 1.75rem;
    --fontsize34: 1.875rem;
    --fontsize36: 1.625rem;
    --fontsize40: 2.5rem;
    --fontsize46: 2.875rem;
    --fontsize54: 3.0rem;
    --fontsize80: 3.5rem;
  }
}

@media screen and (max-width: $mobileScreenPotrait-767) {
  :root {
    --fontsize5: 0.5rem;
    --fontsize10: 0.625rem;
    --fontsize12: 0.75rem;
    --fontsize14: 0.875rem;
    --fontsize16: 1rem;
    --fontsize16-5: 0.77rem;
    --fontsize17: 0.90rem;
    --fontsize17-5: 1rem;
    --fontsize18: 0.875rem;
    --fontsize19: 0.938rem;
    --fontsize20: 1rem;
    --fontsize21: 1.063rem;
    --fontsize22: 1.125rem;
    --fontsize23: 1.188rem;
    --fontsize24: 1rem;
    --fontsize26: 1.25rem;
    --fontsize28: 1.5rem;
    --fontsize30: 1.25rem;
    --fontsize32: 1.75rem;
    --fontsize34: 1.875rem;
    --fontsize36: 1.625rem;
    --fontsize40: 2.5rem;
    --fontsize46: 2rem;
    --fontsize54: 2.25rem;
    --fontsize80: 2.5rem;
  }
}