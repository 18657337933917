/* Media quires resolution screen sizes*/

$ExtraLargeScreen-1920: "1920px";
$ExtraLargeScreen-1919: "1919px";
$Largescreen-1798: "1798px";
$Largescreen-1760: "1760px";
$Largescreen-1700: "1700px";
$Largescreen-1600: "1600px";
$Largescreen-1500: "1500px";
$Largescreen-1440: "1440px";
$ScreenMedium-1366: "1366px";
$ScreenMedium-1365: "1365px";
$ScreenMedium-1300: "1300px";
$screenMedium-1200: "1200px";
$screenMedium-1024: "1024px";
$screenMedium-1023: "1023px";
$screenMedium-992: "992px";
$screenMedium-991: "991px";
$mobileScreenPotrait-768: "768px";
$mobileScreenPotrait-767: "767px";
$smallmobileScreenPotrait577: "577px";
$smallmobileScreenPotrait576: "576px";
$smallmobileScreenPotrait568: "568px";
$smallmobileScreenPotrait500: "500px";
$smallmobileScreenPotrait499: "499px";
$smallmobileScreenPotrait440: "440px";
$smallmobileScreenPotrait441: "441px";
$smallmobileScreenPotrait420: "420px";
$smallmobileScreenPotrait400: "400px";
$smallmobileScreenPotrait375: "375px";
$smallmobileScreenPotrait360: "360px";
$smallmobileScreenPotrait: "330px";
