//saco based custom colors

$brand-color: #B61828;
$brand-variant1:#E31119;
$white-color: #ffffff;
$white-variant1: #FAFAFA;
$black-color: #000000;
$black-variant1: #0A0A0A;
$black-variant2: #1C1C1C;
$black-variant3: #495057;
$grey-variant1:#575A5D;
$grey-variant2:#494949;
$grey-variant3: #95989C;
$grey-variant4: #888888;
$grey-variant5: #E3E6E6;
$grey-variant6: #eaeded;
$grey-variant7: #EFEFEF;
$grey-variant8: #E6E7E8;
$gray-variant9:#f1f1f1;
$gray-variant10:#c1c1c1;
$gray-variant11:#BDC4CD;
$gray-variant12: #F8F8F8;
$gray-variant13: #F3F3F3;
$gray-variant14: #EDEDED;
$gray-variant15: #D9D9D9;
$gray-variant16: #D2D2D2;
$background-color:#f7fafc;

$border-color: $grey-variant5;
$border-variant1:#DFDFDF;
$primary-color:#0F6CB5;
$color-success:#028C27;
$blue-header-bg: #191970;
$footer-primary-bg: #191970;
$blue-menu-bar-bg: #153D64;
$footer-secondary-bg: #002060;
$blue-variant1:#DFF0FE;
$blue-variant2:#C1DFF6;
$blue-variant3:#E9F6FF;
$blue-variatn4: #E3F3FF;
$warning: #FC971F;
$bronze-color: #8F7E65;
$silver-color: #95989C;
$gold-color:#C48D2B;
//$gold-color:#C8B682;
$club-color: #F0F0F0;
$green-success: #C4FFD6;
$green-variant1: #008836;
$bg-warning: #fff3e2;
$saco-brand:#75C159;
$ctaLink: #2be764;
$ctaLink2: #aac52a;
$ctaLinkColor: #151515;


// new CTA links colors
$cta-primary: #FF940C;
$cta-primary-hover: #FFC000;
$cta-secondary: $blue-header-bg;
$cta-secondary-hover: $blue-menu-bar-bg;
